<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col :span="6" style="display: block">
          <a-form-item label="保险公司分类">
            <a-select :allowClear="true" style="width: 150px;" v-model="searchForm.BaseInsurance">
              <a-select-option v-for="n in baseInsuranceNameList" :key="n" :value="n">
                {{n}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="保险公司名称">
            <a-input placeholder="保险公司名称" v-model="searchForm.InsuranceName" />
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='保险公司工号'>
            <a-input placeholder="InsuranceAccount" v-model="searchForm.InsuranceAccount"/>
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="状态">
            <a-select :allowClear="true" v-model="searchForm.Status">
              <a-select-option value="1">
                启用
              </a-select-option>
              <a-select-option value="-1">
                停用
              </a-select-option>
              <a-select-option value="-2">
                删除归档
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="生效时间">
            <a-range-picker v-model="searchForm.StartAt" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="失效时间">
            <a-range-picker v-model="searchForm.EndAt" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="建立时间">
            <a-range-picker v-model="searchForm.CreatedAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <!-- <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-popover title="导入模板">
            <template slot="content">
              <a :href="`${$appBaseUrl}/excelTmp/客户导入模板.xlsx`"  target="_blank" download>下载模板</a>
            </template>
            <a-upload
              name="file"
              :file-list="uploadList"
              :action="fileUrl"
              :headers="fileUploadHeaders"
              :multiple="false"
              @change="handleChangeFile"
            >
              <a-button icon="cloud-upload" type="primary">批量导入</a-button>
            </a-upload>
          </a-popover>
      </a-row> -->
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button icon="plus" style="background-color: #87d068;color: #fff;border-color: #87d068;" @click="addClick">新建</a-button>
          <a-divider type="vertical" />
          <!-- <a-button icon="cloud-download" style="background-color: #909399;color: #fff;border-color: #909399;" @click="exportExcel">导出当前结果</a-button>
          <a-divider type="vertical" /> -->
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 750 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #status="status">
          <a-tag :color="$statusEnum.rateStatus[status].color">{{$statusEnum.rateStatus[status].text}}</a-tag>
        </template>
        <template #action="data">
          <div style="display: flex; flex-wrap: wrap">
            <div style="padding-bottom: 10px">
              <a-tag @click="handleEdit(data)">编辑</a-tag>
            </div>
            <div tyle="padding-bottom: 10px" v-if="$root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/Rate/Edit')">
              <a-tag v-if="data.status == 1" color="red" @click="changeStatus(data)">停用</a-tag>
              <a-tag v-if="data.status == -1" color="green" @click="changeStatus(data)">启用</a-tag>
            </div>
          </div>
          
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal :width="700" :visible="addFormModalShow" title="政策设置" @ok="handleAddOk" @cancel="addFormModalShow = false">
      <a-form-model ref="reateSetForm" :model="addForm" :rules="addFormRules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="保险公司分类" prop="baseInsurance">
              <a-select
                v-model="addForm.baseInsurance"
              >
                <a-select-option
                  v-for="it in baseInsuranceNameList"
                  :key="it"
                  >{{ it }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
        <a-form-model-item label="保险公司名称" prop="insuranceName">
          <a-input v-model="addForm.insuranceName" />
        </a-form-model-item>
        <a-form-model-item label="保险公司工号" prop="insuranceAccount">
          <a-input v-model="addForm.insuranceAccount" />
        </a-form-model-item>
        <a-form-model-item label="生效时间" prop="startAt">
            <a-date-picker
              v-model="addForm.startAt"
              :disabled-date="disabledStartDate"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Start"
            />
          </a-form-model-item>
          <a-form-model-item label="失效效时间" prop="endAt">
            <a-date-picker
              v-model="addForm.endAt"
              :disabled-date="disabledEndDate"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="End"
            />
          </a-form-model-item>
        <a-form-model-item label="进入政策" prop="inRate">
          <a-input-number v-model="addForm.inRate" :min="1" :max="100" />
        </a-form-model-item>
        <a-form-model-item label="放出政策" prop="outRate">
          <a-input-number v-model="addForm.outRate" :min="1" :max="100" />
        </a-form-model-item>
        <a-form-model-item label="服务费提点比例" prop="chargeRate">
          <a-input-number v-model="addForm.chargeRate" :min="0" :max="100" />
        </a-form-model-item>
        <a-form-model-item label="积分不足扣除服务费比例" prop="scoreNotEnoughServiceRate">
          <a-input-number v-model="addForm.scoreNotEnoughServiceRate" :min="1" :max="100" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remarks">
          <a-textarea
            v-model="addForm.remarks"
            :auto-size="{ minRows: 2, maxRows: 3 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal :visible="sending" :footer="null" :maskClosable="false" :keyboard="false" :closable="false">
      <div style="text-align: center;">
        <div>{{sendingHint}}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import DownloadJS from 'downloadjs'
import screenfull from 'screenfull'
import { LossRate, Utils } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"BaseInsurance":"","InsuranceName":"","InsuranceAccount":"","StartAt":[],"EndAt":[],"CreatedAt":[],"Status":""}'
const columns = [
  {
    title: '保险公司分类',
    width: 150,
    dataIndex: 'baseInsurance',
  },
  {
    title: '保险公司名称',
    width: 150,
    dataIndex: 'insuranceName',
  },
  {
    title: '保险公司账号',
    width: 150,
    dataIndex: 'insuranceAccount',
  },
  {
    title: '进入政策',
    width: 150,
    dataIndex: 'inRate',
  },
  {
    title: '放出政策',
    width: 150,
    dataIndex: 'outRate',
  },
  {
    title: '服务费提点比例',
    width: 150,
    dataIndex: 'chargeRate',
  },
  {
    title: '状态',
    width: 100,
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '备注',
    width: 150,
    dataIndex: 'remarks',
  },
  {
    title: '建立日期',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  name: 'LossRate',
  mixins: [aliveVM, mixinTableList],
  async created () {
    this.fileUrl = `${this.$appBaseUrl}${LossRate}/ImportList`
    this.fileUploadHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    const baseColumns = []
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
    // 
    this.fetch()
  },
  async mounted() {
    await this.getBaseInsuranceNameList();
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      // table使用
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${LossRate}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      rowKey: 'uuid',
      // 其他
      moment,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      baseNameList: [],
      statusList: [
        { value: '1', title: '启用' },
        { value: '-1', title: '停用' }
      ],
      bankAccountTypeList: [
        '个人账户',
        '企业账户'
      ],
      sending: false,
      sendingHint: '',
      // 添加
      baseInsuranceNameList: [],
      addFormModalShow: false,
      addFormModalMode: 'create',
      addForm: {
        baseInsurance: '',
        insuranceName: '',
        insuranceAccount: '',
        startAt: null,
        endAt: null,
        inRate: 0,
        outRate: 0,
        chargeRate: 0,
        scoreNotEnoughServiceRate: 0,
        remarks: ''
      },
      addFormRules: {
        insuranceName: [{ required: true, message: '请输入', trigger: 'blur' }],
        baseInsurance: [{ required: true, message: '请选择', trigger: 'change' }],
        insuranceAccount: [{ required: true, message: '请输入', trigger: 'blur' }],
        startAt: [{ required: true, message: '请选择', trigger: 'change' }],
        inRate: [{ required: true, message: '请输入', trigger: 'blur' }],
        outRate: [{ required: true, message: '请输入', trigger: 'blur' }],
        chargeRate: [{ required: true, message: '请输入', trigger: 'blur' }],
        scoreNotEnoughServiceRate: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      // 选择缓存
      selectedLossShopName: []
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'StartAt' || k === 'EndAt' || k === 'CreatedAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // 其他
    disabledStartDate(startValue) {
      const endValue = this.addForm.endAt;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.addForm.startAt;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    async getBaseInsuranceNameList() {
      const baseNameListRes = await this.$axios
        .get(`${Utils}/InsuranceBaseList`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!baseNameListRes.status) {
        this.$message.error(baseNameListRes.message);
        return;
      }
      this.baseInsuranceNameList = baseNameListRes.data;
    },
    addClick() {
      this.$set(this, 'addForm', {
        baseInsurance: '',
        insuranceName: '',
        insuranceAccount: '',
        startAt: null,
        endAt: null,
        inRate: 0,
        outRate: 0,
        chargeRate: 0,
        scoreNotEnoughServiceRate: 0,
        remarks: ''
      })
      this.addFormModalMode = 'create'
      this.addFormModalShow = true
    },
    handleChangeFile (info) {
      this.uploadList = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        return
      } else if (info.file.status === 'done') {
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          return;
        }
        this.fetch();
      }
    },
    handleEdit (data) {
      this.addForm.baseInsurance = data.baseInsurance
      this.addForm.insuranceName = data.insuranceName
      this.addForm.insuranceAccount = data.insuranceAccount
      this.addForm.startAt = data.startAt ? moment(data.startAt).format('YYYY-MM-DD HH:mm:ss') : null
      this.addForm.endAt = data.endAt ? moment(data.endAt).format('YYYY-MM-DD HH:mm:ss') : null
      this.addForm.inRate = data.inRate
      this.addForm.outRate = data.outRate
      this.addForm.chargeRate = data.chargeRate ? data.chargeRate : 0
      this.addForm.scoreNotEnoughServiceRate = data.scoreNotEnoughServiceRate ? data.scoreNotEnoughServiceRate : 0
      this.addForm.remarks = data.remarks
      this.addFormModalMode = 'edit'
      this.nowEditUuid = data.uuid
      this.addFormModalShow = true
      
    },
    handleAddOk () {
      this.$refs.reateSetForm.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容');
          return
        }
        const postData = JSON.parse(JSON.stringify(this.addForm))
        let url = ''
        if (this.addFormModalMode == 'create') {
          url = `${LossRate}/Create`
        } else {
          url = `${LossRate}/Edit?uuid=${this.nowEditUuid}`
        }
        const res = await this.$axios.post(url, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!res.status) {
          this.$message.error(res.message)
            return
        }
        this.addFormModalShow = false
        this.$message.success('操作成功')
        this.fetch()
      });
    },
    async exportExcel() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.sending = true
      this.sendingHint = '正在获取数据'
      const apiRes = await this.$axios.post(`${LossRate}/ExportExcel`, {
        results: 20000,
        page: 1,
        searchParams,
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      DownloadJS(window.atob(apiRes.data), '客户列表数据.xlsx')
    },
    async changeStatus(data) {
      const apiRes = await this.$axios.put(`${LossRate}/ChangeStatus?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.fetch()
    }
  }
}
</script>

<style>

</style>